import React from 'react';

const Check = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20">
    <g stroke="#fff" strokeWidth="2.3" fill="none">
      <circle cx="10" cy="10" r="8.5"/>
      <path d="M5.2,10 8.5,13.4 14.8,7.2"/>
    </g>
  </svg>
)

export default Check





