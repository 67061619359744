import React from 'react';

export const FormTextarea = (options) => {
  const {fieldId, fieldName, required, handlers, rows=5, errorText='Fehler'} = options;
  const {inputChangeHandler, inputBlurHandler, input, errors} = handlers;

  const styleOK = 'shadow appearance-none border rounded w-full sm:w-10/12 py-2 px-3 text-gray-700 text-base leading-tight focus:outline-none focus:shadow-md'
  const styleNOK = styleOK + ' border-red-500'
  return(
    <div className="mb-4">
      <div className="flex flex-wrap sm:flex-nowrap sm:flex-row items-start items-center">
        <label className="text-gray-700 text-sm font-bold mx-2 w-20" htmlFor={fieldId}>{fieldName}{required ? '*':''}</label>
        <textarea
          className={errors.has(fieldId) ? styleNOK : styleOK}
          id={fieldId}
          name={fieldId}
          rows={rows}
          defaultValue={input[fieldId]}
          onChange={(e) => inputChangeHandler(e)}
          onBlur={() => inputBlurHandler(fieldId)}
        />
      </div>
      <div className="text-red-700 text-xs pl-24">{errors.has(fieldId) ? errorText : ''}</div>
    </div>
  )
}
