import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import Layout from '../components/layout/Layout';
import {Link} from 'gatsby';
import {FormField} from '../components/FormField';
import {FormTextarea} from '../components/FormTextarea';
import axios from '../functions/axios.config';
import LoadingSpinner from '../svg/LoadingSpinner'
import Check from '../svg/Check';
import {trackScreenView, trackEvent} from '../functions/analytics';

const LoadingButton = () => {
  return(
    <button
      className="bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-md ml-3 inline-flex cursor-wait"
      type="button"
    >
      <LoadingSpinner /> Senden
    </button>
  )
}

const SendButton = () => {
  return(
    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-md ml-3 inline-flex"
      type="submit"
    >
      Abschicken
    </button>
  )
}

const ReadyButton = () => {
  return(
    <button
      className="bg-blue-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-md ml-3 inline-flex cursor-default"
      type="button"
    >
      <Check /> Vielen Dank für Ihre Nachricht
    </button>
  )
}

const Kontakt = () => {

  const inputDefaults = {
    firstName: '',
    lastName: '',
    company: '',
    phone: '',
    email: '',
    message: '',
  }

  // local state - form controller
  const [input, setInput] = useState({...inputDefaults})
  const [errors, setErrors] = useState(new Set());
  const [forceUpdate, setForceUpdate] = useState(1);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);


  const inputChangeHandler = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    setInput({
      ...input,
      [key]: value,
    })
    // if error exits, we want a direct re-evaluation. otherwise wait until blur
    errors.has(key) ? validateInput(event.target) : null
  }

  const inputBlurHandler = (fieldId) => {
    const target = {name: fieldId, value: input[fieldId]}
    validateInput(target)
    setForceUpdate(forceUpdate+1)
  }

  const handlers = {
    inputChangeHandler: inputChangeHandler,
    inputBlurHandler: inputBlurHandler,
    input: input,
    errors: errors,
  }

  const validateForm = () => {
    for(const field in inputDefaults) {
      validateInput({name:field, value:input[field]})
    }
    setForceUpdate(forceUpdate+1) //hack to force rerender to show errors
  }

  const validateInput = (target) => {
    if(validationRuleContainsError(target.name, target.value)) {
      setErrors(errors => errors.add(target.name))
    } else {
      const newErrors = errors
      newErrors.delete(target.name)
      setErrors(newErrors)
    }
  }

  const validationRuleContainsError = (name, value) => {
    let error;
    switch (name) {
      case 'firstName':
        if (typeof(value) === 'undefined' || value === null || value === '' || value.length < 2) error = true; break;
      case 'lastName':
        if (typeof(value) === 'undefined' || value === null || value === '' || value.length < 2) error = true; break;
      case 'phone':
        if (typeof(value) === 'undefined' || value === null || !RegExp('^[0-9\\-\(\)\+\/ ]{7,25}$').test(value)) error = true; break;
      case 'email':
        if (typeof(value) === 'undefined' || value === null || !RegExp("^.+@.+(\\.).+$").test(value)) error = true; break;
      case 'message':
        if (typeof(value) === 'undefined' || value === null || value === '' || value.length < 4) error = true; break;
      default:
        error = false;
    }
    return error;
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await validateForm()
    console.log(errors)
    if(errors.size > 0) {
      //warn?
      //console.log(errors)
      trackScreenView('Kontaktanfrage Validierungsfehler')
    } else {
      postMessage(input)
      trackScreenView('Kontaktanfrage gesendet')
      trackEvent('contact_request')
      //console.log('all OK')
    }
  }

  const postMessage = (payload) => {
    setLoading(true)
    axios.post('/contact',payload)
      .then ( response => {
        //console.log('message sent')
        setSuccess(true)
        setLoading(false)
        return true
      })
      .catch(error => {
        //console.log('API error')
        setSuccess(false)
        setLoading(false)
        return false
      });
  }

  let formButton = <SendButton />
  if(loading) formButton = <LoadingButton />
  if(success) formButton = <ReadyButton />


  return (
    <div className="application">
      <Helmet>
        <title>Kontakt | Automatiktür24</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Automatiktür24 Kontaktformular" />
        <html lang="de" />
      </Helmet>
      <Layout>

        <section className="pt-20 md:pt-40">
          <div className="container mx-auto lg:px-8 lg:flex">
            <div className="text-center lg:text-left lg:w-1/3">
              <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
                Kontakt
              </h1>
              <p className="text-xl lg:text-2xl mt-6 font-light">
                Automatiktür24 GmbH
              </p>
              <p className="pb-10">
                Tel. <a href="tel:+4975245092939">07524 / 509 29 39</a><br/>
                <a href="mailto:info@automatiktuer24.de">info@automatiktuer24.de</a>
              </p>
            </div>

            <div className="lg:w-2/3 xl:w-1/2">

              <form id="contactForm" onSubmit={handleSubmit} autoComplete="on" className="bg-gray-100 shadow-md rounded px-4 md:px-8 pt-6 pb-8 mb-4 text-left">
                <div className="flex justify-end text-sm text-gray-500 mb-4 mx-4">*Pflichtfeld</div>

                {FormField({
                  fieldId: 'firstName',
                  fieldName:'Vorname',
                  required: true,
                  handlers: handlers,
                  errorText: 'Bitte geben Sie Ihren Vornamen an.'
                })}
                {FormField({
                  fieldId: 'lastName',
                  fieldName:'Nachname',
                  required: true,
                  handlers: handlers,
                  errorText: 'Bitte geben Sie Ihren Nachnamen an.'
                })}
                {FormField({
                  fieldId: 'company',
                  fieldName:'Firma',
                  required: false,
                  handlers: handlers,
                })}
                {FormField({
                  fieldId: 'phone',
                  fieldName:'Telefon',
                  required: true,
                  handlers: handlers,
                  errorText: 'Bitte geben Sie eine gültige Telefonnummer an.'
                })}
                {FormField({
                  fieldId: 'email',
                  fieldName:'E-Mail',
                  required: true,
                  handlers: handlers,
                  errorText: 'Bitte geben Sie eine gültige E-Mail-Adresse an.'
                })}
                {FormTextarea({
                  fieldId: 'message',
                  fieldName:'Ihre Nachricht',
                  required: true,
                  handlers: handlers,
                  errorText: 'Bitte geben Sie eine Nachricht ein.'
                })}

                <label className="flex justify-start items-start">
                  <div className="select-none text-gray-700 text-sm">
                    Mit dem Absenden der Kontaktanfrage willigen Sie in die Kontaktaufnahme ein und bestätigen die Kenntnisnahme unserer <Link to="/datenschutz/">Datenschutzerklärung</Link>.
                  </div>
                </label>

                <div className="flex items-center justify-end mt-8">
                  {formButton}
                </div>
              </form>

            </div>
          </div>
        </section>

      </Layout>
    </div>
  );
}

export default Kontakt
